import type { CSSProperties, FunctionComponent } from 'react'

import { useMenuActivation } from '@sporza/hooks'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

import Logo from '../../atoms/logo'
import { Breakpoints } from '../../design-tokens/breakpoints'
import Button from '../../molecules/button'
import Navigation, { NavigationItem } from '../navigation'

import MenuToggle from './components/menu-toggle'
import MobileMenu from './components/mobile-menu'
import styles from './header.module.scss'

interface HeaderProps {
  topNavigationItems?: NavigationItem[]
  mainNavigationItems?: NavigationItem[]
  mainNavigationRightItems?: NavigationItem[]
  mobileNavigationItems?: NavigationItem[]
  offset?: number
  showLogin?: boolean
  azLink?: string
}

type SSOLogin = Partial<Element> & {
  theme?: string
  class?: string
  style?: CSSProperties
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      ['sso-login']: SSOLogin;
    }
  }
}

const Header: FunctionComponent<HeaderProps> = ({
  showLogin,
  azLink,
  topNavigationItems,
  mainNavigationItems = [],
  mainNavigationRightItems = [],
  mobileNavigationItems ,
  offset = 1
}) => {
  const [open, setOpen] = useState<any>([])

  // temp programmagids fix (non remix), remove when all remix
  const {
    activeItems: activeRightItems,
    activeMobileItems: activeMobileRightItems
  } = useMenuActivation(mainNavigationRightItems)
  const activeMobileNavigationItems = activeMobileRightItems || mobileNavigationItems || []
  // end temp fix

  const login = showLogin && {
    title: 'login',
    component: <sso-login theme="n1" class={styles.login}></sso-login>
  }

  const topNavigationItemsWithExtras = topNavigationItems && login ? [
    ...topNavigationItems,
    login
  ] : topNavigationItems

  const topNavigationMobileLeft: NavigationItem[] = [
    {
      component: <Button
        variant={'primary'}
        iconBefore={'search'}
        withHover={false}
        title={'zoek'}
        href={'/nl/zoek'}
      />
    }
  ]
  const topNavigationMobileRight: NavigationItem[] = login ? [
    login,
    {
      component: <MenuToggle />
    }
  ] : [{
    component: <MenuToggle />
  }]

  const mobileMainNavigationItems = JSON.parse(JSON.stringify(mainNavigationItems)).map((item: any) => {
    item.iconBefore = undefined

    return item
  })

  const [sticky, setSticky] = useState('')
  const [scroll, setScroll] = useState(0)

  // on render, set listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [offset])

  let lastScroll = 0
  let lastDirection = 'up'
  let scrollAnchor: number

  const getOldAEMOffset = () => {
    // remove when all pages are remix
    return document.querySelector('[data-ad-type="large_leaderboard"]')?.clientHeight || 0
  }

  const handleScroll = () => {
    const headerHeight = 220
    const currentOffset = offset > 1 ? offset : getOldAEMOffset()
    const scrollTop = window.scrollY
    const isSticky = scrollTop >= currentOffset
    const stickyClass = isSticky ? styles.sticky : ''
    setSticky(stickyClass)
    if (isSticky && window.innerWidth >= Breakpoints.medium){
      if (scrollAnchor === undefined){
        scrollAnchor = scrollTop
      }
      const direction = scrollTop > lastScroll ? 'down' : 'up'

      const diff = scrollTop - scrollAnchor
      const limitedDiff = diff > headerHeight ? headerHeight : (diff < 0 ? 0 : diff)

      if (direction === 'down' && limitedDiff === headerHeight){
        scrollAnchor = scrollTop - headerHeight
      } else if (direction === 'down' && lastDirection === 'up'){
        scrollAnchor = scrollTop
      }

      setScroll(-limitedDiff)

      lastScroll = scrollTop
      lastDirection = direction
    } else {
      setScroll(0)
    }
  }

  const sportActiveMobileItems = () => {
    let r = activeMobileNavigationItems

    activeMobileNavigationItems.forEach((item: NavigationItem) => {
      if (item?.title === 'sporten' && item.items) {
        r = item.items
      }
    })

    return r
  }

  const activeMegaMenuItems = () => {
    let r

    const loopItems = (items: NavigationItem[], level = 1) => {
      items.forEach((item: NavigationItem) => {
        const targetId = `${item.level || level}-${item.title}`
        if (open.includes(targetId)) {
          r = item.items
        }

        if (item.items) loopItems(item.items, level++)
      })
    }

    loopItems(activeMobileNavigationItems)

    return r
  }

  return <div className={clsx(styles.headerWrapper, sticky)}>
    <header className={clsx(styles.header)} style={{ top: `${scroll}px` }} >
      <input type="checkbox" id="toggle-checkbox-menu" hidden={true}/>

      <div className={clsx(
        styles.topHeader,
        'headerTop'
      )}>
        <div className={styles.topHeaderInner}>
          {
            topNavigationMobileLeft
            && <Navigation
              variant={'top'}
              className={styles.topNavigationMobile}
              items={topNavigationMobileLeft}
            />
          }
          <Logo
            className={styles.logo}
            logo={'sporza'}
            darkMode={true}
            link={'/'}
          />
          {
            topNavigationItemsWithExtras
            && <Navigation
              variant={'top'}
              className={styles.topNavigation}
              items={topNavigationItemsWithExtras}
            />
          }
          {
            topNavigationMobileRight
            && <Navigation
              variant={'top'}
              className={styles.topNavigationMobile}
              items={topNavigationMobileRight}
            />
          }
        </div>
      </div>

      <div className={styles.mainNavigationWrapper}>
        <Navigation
          variant={'main'}
          overflow={false}
          open={open}
          action={(targetId, { level, ...item }) => {
            if (!open.includes(targetId) && !item.href){
              open[level] = targetId
              setOpen(open.slice(0, level+1))
            } else {
              setOpen(open.slice(0, level))
            }
          }}
          className={styles.mainNavigation}
          items={mainNavigationItems}
          rightItems={activeRightItems}
          azLink={azLink}
        />
      </div>

      <div className={clsx(
        'mobileMenu',
        styles.mobileMenuWrapper
      )}>
        {sportActiveMobileItems() && <Navigation
          variant={'main'}
          className={styles.megaNavigation}
          items={sportActiveMobileItems()}
          action={(targetId, { level, ...item }) => {
            if (!open.includes(targetId) && !item.href){
              open[level] = targetId
              setOpen(open.slice(0, level+1))
            } else {
              setOpen(open.slice(0, level))
            }
          }}
        />}
        {activeMegaMenuItems() && <Navigation
          variant='tertiary'
          className={styles.megaNavigation}
          items={activeMegaMenuItems()}
        />}
        <MobileMenu
          mainNavigationItems={mobileMainNavigationItems}
          bottomNavigationItems={activeRightItems}
          footerNavigationItems={topNavigationItems}
          azLink={azLink}
        />
      </div>

    </header>
  </div>
}

export default Header

export type {
  HeaderProps
}
